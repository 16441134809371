:root {
  --PrimaryColor: rgb(128, 128, 128);
  --ContainerColor: rgb(200, 200, 200);
  --SecondaryColor: rgb(129, 186, 247);
}

* {
  font-family: "Calibri", Helvetica, sans-serif;
  font-size: 22px;
  box-sizing: border-box;
}

header {
  background: white;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 15px 0px;
}

#logo {
  width: 200px;
  max-width: 100%;
  height: auto;
  margin: auto;
}

a {
  text-decoration: none;
  color: var(--SecondaryColor);
  font-weight: bolder;
}

a:focus {
  border-bottom: 2px solid;
  border-color: var(--SecondaryColor);
}

#strip {
  background-color: var(--PrimaryColor);
  color: white;
  text-align: center;
}

#main-content { min-height: calc(100vh - 39px); }

#hero {
  background-image: url(./images/home-edit-1.png);
  height: 1000px;
}

#hero2 {
  background-image: url(./images/about-final.jpg);
}

#hero3 {
  background-image: url(./images/contact-edit-1.png);
  padding-bottom: 10rem;
}

#hero, #hero2, #hero3 {
  background-size: cover;
  background-position: center;
  width: 100%;
  text-align: center;
  color: white;
  padding-top: 5rem;
}

#hero, form {
  text-shadow: 0 0 10px #000000;
}

#hero h1 {
  font-size: 64px;
  padding-top: 50px;
}

#services {
  text-align: start;
  list-style-type: none;
  width: 30%;
  padding-top: 50px;
}

#services li {
  font-size: 38px;
}

#form-container, #about-us {
  background: linear-gradient(var(--PrimaryColor), var(--ContainerColor));
  color: white;
  border: 3px solid black;
  box-shadow: 25px 25px 25px rgba(0, 0, 0, .7);
  padding: 10px;
  border-radius: 5px;
}

#form-container h2 {
  text-align: center;
  color: var(--SecondaryColor)
}

#about-us {
  margin: 5%;
  text-shadow: 0 0 10px #000000;
}

#submit-button-container { text-align: center; }

#submitBtn {
  background: var(--SecondaryColor);
  border: 2px solid black;
  border-radius: 0.25;
  box-shadow: 5px 5px 5px gray
}

#submitBtn:hover { scale: 1.2; }

footer {
  position: relative;
  left: 0;
  bottom: 0;
  background: var(--PrimaryColor);
  color: white;
  text-align: center;
  padding: 10px;
}

footer h6 { margin: 0; }

@media (max-width: 1200px) {
  #logo {
    width: 150px;
  }

  #hero {
    background-position: -15dvh;
    height: 700px;
  }

  #hero h1 {
    font-size: 48px;
  }

  #services li {
    font-size: 25px;
  }
}

@media (max-width: 850px) {
  * {
    font-size: 18px;
  }
}

@media (max-width: 600px) {
  * {
    font-size: 12px;
  }

  #logo {
    width: 64px;
  }

  #hero {
    background-position: -25dvh;
  }

  #hero h1 {
    font-size: 36px;
  }

  #services {
    width: 50%;
  }

  #services li {
    font-size: 16px;
  }
}